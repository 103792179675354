<template>
  <div
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    :data-type="context.type"
  >
    <input
      v-model="value"
      type="text"
      v-bind="context.attributes"
      @focus="focus_handler"
      @blur="blur_handler"
      @change="applyFormRules"
      class="el-input__inner"
    />
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      value: null,
      min: 0,
      max: 100,
    };
  },

  computed: {},

  watch: {
    value: {
      handler(data) {
        const formatted = this.format(data);
        this.context.rootEmit("input", formatted);
      },
    },
  },

  created() {
    this.value = `${this.context.model}%`;

    this.min = this.context.attributes.min
      ? Number(this.context.attributes.min)
      : 0;
    this.max = this.context.attributes.max
      ? Number(this.context.attributes.max)
      : 100;
  },

  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    focus_handler() {
      this.value = this.format(this.value);
    },

    blur_handler() {
      this.value = `${this.format(this.value)}%`;
    },

    format(data) {
      const comma_by_dot = data.replace(/,/g, ".");
      const everything_but_numbers_and_dot = comma_by_dot.replace(
        /[^0-9.]/g,
        ""
      );

      if (this.min && isNaN(Number(everything_but_numbers_and_dot))) {
        return String(this.min);
      }

      const percentage = Number(everything_but_numbers_and_dot);

      if (this.min && percentage < this.min) {
        return String(this.min);
      }

      if (this.max && percentage > this.max) {
        return String(this.max);
      }

      return String(percentage);
    },
  },
};
</script>
