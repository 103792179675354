import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta);
/* Layout Component  */
const Home = () => import('@/views/Home.vue');

const configurationLayout = () => import ('@/views/Configuration.vue');
const Dashboard = () =>import('@/views/Dashboard');
const Expire = () => import("../components/account/Expire.vue");
const Scanner = () =>import('@/views/auth/Scanner');
// Auth routes
const SignIn = () =>import('@/views/auth/SignIn');
const SSOSignIn = () =>import('@/views/auth/SSOSignIn');
const SignUp = () =>import('@/views/auth/SignUp');
const SSOSignUp = () => import('@/views/auth/SSOSignUp');

const UserRegistrationProfile = () => import('@/views/auth/UserRegistrationProfile');
const UserVerify = () =>import('@/views/auth/UserVerify');
const SSOUserVerify = () =>import('@/views/auth/SSOUserVerify')
const SendVerification = () =>
    import('@/views/auth/SendVerification')
const JoinUs = () =>
    import('@/views/auth/JoinUs')

const ForgotPassword = () =>
    import('@/views/auth/ForgotPassword')
const ForgotPasswordVerification = () =>
    import('@/views/auth/ForgotPasswordVerification')
const InviteUser = () =>
    import('@/views/auth/InviteUser')

// Profile routes
const Profile = () =>
    import('@/views/account/Profile');
const Profilesettings = () =>
    import('@/views/account/Profilesettings');
const PersonalInformation = () =>
    import("@/components/account/PersonalInformation");
// const SubscriptionInformation = () =>
//     import("@/components/account/SubscriptionInformation");
const Brandings = () =>
    import("@/components/account/Branding");
const CompanyUsers = () =>
    import("@/components/account/CompanyUsers");
const Signature = () =>
    import("@/components/account/Signature");
const Initial = () =>
    import("@/components/account/Initial");
const UpdatePassword = () =>
    import("@/components/account/UpdatePassword");
const Permissions = () =>
    import("@/components/account/Permissions");
const Statistics = () =>
    import("@/components/account/dashboardStats");
const AppCredentials = () =>
    import("@/components/account/AppCredentials");
const SharePointIntegrationCredentials = () =>
    import("../components/Configuration/Storage/SharePointIntegrationCredentials.vue")
const DocumentExpirationReminders = () =>
    import("@/components/account/DocumentExpirationReminders");
const ApplicationSettings = () =>
    import("@/components/account/ApplicationSettings");
const Notifications = () =>
    import("@/components/account/Notifications");

const GlobalFilters = () =>
    import("@/components/account/GlobalFilters")
const AppModeConfiguration = () =>
    import("@/components/account/AppModeConfiguration")
// Branding
const Branding = () =>
    import("@/views/account/Branding");
const signSuccessMessage = () =>
    import('@/components/signSuccessMessage')
const SignDocuments = () =>
    import('@/components/SignDocuments')
const FinishLaterSuccess = () =>
    import('@/components/FinishLaterSuccess')

// Applications routes
const ApplicationList = () => import('@/components/application/applicationsList.vue');
const DeveloperPortal=() => import ('@/components/DeveloperPortal.vue');
const ClientAppDetail=() => import ('@/components/ClientAppDetail.vue');
const PublishedAppsList = () => import('@/components/application/publishedApps.vue');
const ApprovalAppsList = () => import('@/components/application/appApprovals.vue')
const UploadAppsSteps = () => import('./../components/application/uploadAppSteps.vue');
const InstalledAppList = () => import('@/components/application/installedApps.vue')
//from scratch Development screen routes
const InstallApp = () => import("./../components/application/employeeManagementPopUp.vue")
const ListAllSteps = () => import("./../components/application/step-container.vue");
const CreateApplicationFromExcel = () => import("./../components/application/createApplicationFromExcel.vue")
// Contact routes
const Contacts = () =>
    import('@/components/contacts/Contacts');


const AddContacts = () =>
    import('@/components/contacts/AddContact')
const UserDocuments = () =>
    import('@/components/contacts/UserDocuments');

// Contact types routes
const ContactTypes = () =>
    import('@/components/contacts/ContactTypes')

import AuthMiddleware from "@/middleware/auth"

const Documents = () =>
    import('@/components/companyDocuments/AllDocuments')
const RequestDocuments = () =>
    import('@/components/requestDocuments/AllRequestDocuments')
const RequestDocumentsSelectCardType = () =>
    import('@/components/requestDocuments/SelectCardType')
const RequestDocumentDraft = () =>
    import('@/components/requestDocuments/RequestDocumentDraft')
const AllTemplates = () =>
    import('@/components/templates/AllTemplates')

// uploading files
const UploadDocumentPreference = () =>
    import('@/components/companyDocuments/UploadDocumentPreference')
const UploadTemplatePreference = () =>
    import('@/components/companyDocuments/UploadTemplatePreference')

const UploadRequestDocumentPreference = () =>
    import('@/components/requestDocuments/UploadRequestDocumentPreference')

// const ViewEmployeeCustomDraftDocument = () => import('@/components/ViewEmployeeCustomDocumentDraftPreview')
const ViewEmployeeCustomDocument = () =>
    import('@/components/ViewEmployeeCustomDocumentPreview')
const DocumentPreview = () =>
    import('@/components/employeeDocuments/viewDocuments/DocumentPreview')

const DocumentPreviewExpired = () =>
    import('@/components/employeeDocuments/viewDocuments/DocumentPreviewExpired')


const ConfigureEmployeeDocumentDraft = () =>
    import("@/components/ConfigureEmployeeDocumentDraft")

//get link routes
// const PublishedTemplatePreiview = () => import('@/components/templates/ViewPublishedTemplatePreiview.vue');

const sendDocumentOnMail = () =>
    import('@/components/companyDocuments/sendDocumentOnMail')
const sendTemplateOnMail = () =>
    import('@/components/companyDocuments/sendTemplateOnMail');
const MenuManagement = () =>
    import('../views/menuManagement');
const MailConfiguration =() => import ("@/components/Configuration/mail_config.vue");
const IframeLink = () =>
    import('../views/iframeLink.vue')
// const NewOption1=()=>
// import('../components/offerLetters/NewOption1')


// configure pages
const ConfigureCompanyDocument =() =>import ('../components/companyDocuments/configureDocuments/ConfigureCompanyDocument');
const ConfigureCompanyTemplate =() =>import ('../components/companyDocuments/configureDocuments/ConfigureCompanyTemplate');
const ConfigureCompanyTemplatePreview =() =>import ('../components/companyDocuments/configureDocuments/ConfigureCompanyTemplatePreview');
const ConfigureDocTypeDocument =() =>import ('../components/companyDocuments/configureDocuments/ConfigureDocTypeDocument');
const CustomDocumentExecute =() =>import ('../components/executeCompanyDocuments/CustomDocumentExecute');
const StaticDocumentExecute =() =>import ('../components/executeCompanyDocuments/StaticDocumentExecute');
const RequestedDocumentExecute =() =>import ('../components/executeCompanyDocuments/RequestedDocumentExecute');
const CustomDocTypeDocumentExecute =() =>import ('../components/executeCompanyDocuments/CustomDocTypeDocumentExecute');

// Preview Documents
const ViewEmployeeCustomDocumentStatus = () =>
    import('@/components/ViewEmployeeCustomDocumentStatus')
//document flow 
// const ViewDocumentSentWorkflow = () => import('@/components/ViewDocumentSendWorkflow')

const CustomDocumentPreview =() =>import ('../components/previewCompanyDocuments/CustomDocumentPreview');
const StaticDocumentPreview =() =>import ('../components/previewCompanyDocuments/StaticDocumentPreview');
const CustomDocTypeDocumentPreview =() =>import ('../components/previewCompanyDocuments/CustomDocTypeDocumentPreview');

//workflow
const WorkflowList =() =>import ('../components/workFlows/templateWorkflowList');
const AddEditWorkflow =() =>import ('../components/workFlows/addEditWorkflow');
const WorkflowDataList =() =>import ('../components/workFlows/templateWorkflowDataList');

const EmployerCustomDocTypePasswordVerification =() =>import ('../components/employee/executeEmployeeDocuments/EmployerCustomDocTypePasswordVerification');
const EmployerCustomDocumentPasswordVerification =() =>import ('../components/employee/executeEmployeeDocuments/EmployerCustomDocumentPasswordVerification');

//Custom Dashboard
const customDashboardConfig = () => import('@/components/customDashboard/customDashboardConfig.vue');
const customDashboardList = () => import('@/components/customDashboard/customDashboardList.vue');
const customEntityMention = () => import('@/components/widgets/entityMention/index.vue');
const ApplicationUserDashboards = () => import('@/components/entity/ApplicationUserCustomDashboardList.vue');
const PaymentLinkComponent = () => import("@/views/payment-link/payment-link");

const PageCustomDashboard = () => 
    import('@/components/applicationUsers/PageCustomDashboard')
const CompanyUserList = () =>
    import('@/components/company/CompanyUsers');
const NewTemplate = () =>
    import('../views/templates/NewTemplate');
const TemplateList = () =>
    import('../views/templates/TemplatesList');
const ViewTemplate = () =>
    import('@/components/templates/ViewTemplate')
const EntitiesList = () =>
    import('@/components/entity/EntitiesList');
const AddEditEntity = () =>
    import('@/components/entity/AddEditEntity');
const createEntityFromExcel = () =>
    import('@/components/entity/createEntityFromExcel');
const ViewEntity = () =>
    import("@/components/entity/ViewEntity");
const UserTemplatesData = () =>
    import('@/views/users/UserTemplatesData');
const ViewEntityData = () =>
    import('@/components/entity/ViewEntityData');
const EntitiesViewsList = () =>
    import('@/components/entity/EntityCalculationViews');
const EntityViews = () =>
    import('@/components/entity/EntityViews');
const EntitiesViewEdit = () =>
    import('@/components/entity/EntityViewsEdit');
// entity Exectute
const EntityExecute = () =>
    import("@/components/entity/entityExecute");
const EntityExecuteStep = () =>
    import("@/components/entity/entityExecuteSteps");
const PreviewEntityData = () =>
    import("@/components/entity/preview");
const PreviewEntityDataStep = () =>
    import("@/components/entity/previewStep");
const PageNotFound = () => import('@/views/pageNotFound');

const GlobalVariablesList = () =>
    import('../views/globalVariables/GlobalVariablesList');
const GlobalVariableAdd = () =>
    import('../views/globalVariables/GlobalVariableAdd');
const EntityGroupsDashboard = () =>
    import('@/components/Groups/EntityGroups');

const EntityGroupPreview = () =>
    import('../views/entities/EntityGroupPreview')
const EntityGroupPreviewEntityData = () =>
    import('../views/entities/EntityGroupPreviewEntityData');
const NewEmailTemplate = () =>
    import('@/components/emailTemplates/newEmailTemplate.vue');
const EmailTemplatesList = () =>
    import('@/components/emailTemplates/emailTemplatesList.vue');
const NewEmailHeaderFooter = () =>
    import('@/components/emailTemplates/newHeaderFooter.vue')

const Roles = () => import('@/components/Roles/Roles');
const NewFormBuilder = () => import('@/views/formBuilders/NewFormBuilder')
const FormBuilderList = () => import('@/views/formBuilders/FormBuildersList')
const FormBuilderUserData = () => import('@/views/formBuilders/FormBuilderUserData')
const FormbuilderView = () => import('@/components/formBuilders/formbuilderView.vue')
const FormbuilderViewStep = () => import('@/components/formBuilders/formbuilderViewStep.vue')
const FormbuilderDataList = () => import('@/components/formBuilders/formbuilderTemplateDataList')
//const GuestFormbuilderExecute = () => import('@/components/formBuilders/GuestFormbuilderExecute.vue')
const GuestFormbuilderCompleted = () => import('@/components/formBuilders/formbuilderCompletedMessage')
const GuestFormbuilderExecuteStep = () => import('@/components/formBuilders/GuestFormbuilderExecuteStep.vue')
const GuestFormbuilderUserSubmittedDataList = () => import('@/components/formBuilders/FormbuilderUserSubmittedDataList');
//LOgDetails
const logDetails = () => import('@/components/LogDetails/logDetails');

//NEW Form-builder
const FormbuilderNewView = () => import('@/components/formBuilders/formBuilderNewView.vue');
const ApprovalFormsView = () => import('@/components/formBuilders/approvalFormsView.vue');
const FormbuilderApprovalAuth = () => import('@/components/formBuilders/approvalAuthReviewPage.vue');

const SubscriptionInformation = () => import("@/components/account/Subscriptions.vue");
// const PaymentGateway = () => import ("@/components/account/PaymentGateway.vue");
const PaymentIntegrations = () => import("@/components/Configuration/Payment/PaymentIntegrations.vue");

const PaymentIntegrationsList = () => import("@/components/Configuration/Payment/PaymentIntegrationsList.vue");
const PaymentsCards = () => import("../components/Configuration/Payment/Payment.vue");
const paymentStatus = () => import("@/components/payment/payment.vue")


const plansList = () => import("@/components/account/Plans.vue");
const StripePaymentStatus = () => import("@/components/payment/payment-status.vue");
const commonComponentForPayment = () => import("@/components/payment/entity-payment-status"); //swirepay


const Invoice = () => import('@/components/payment/invoice');
// const emailIntegration = () => import('@/components/notifications/emailIntegration.vue');

//oauth
const OauthComponent = () => import('../components/OuathComponent.vue');

//New entity detailed view
const EntityDetailedView = () => import('@/components/entity/EntityDetailedCardView.vue');
const EntityDetailedCardViewForPublic = () => import('@/components/entity/EntityDetailedCardViewForPublic');
import iFrameRoutes from './iFrameRoutes';
import applicationUserRoutes from "./applicationUserRoutes";
const routes = [
    {
        path: '/',
        component: Home,
        name: "home",
        beforeEnter: AuthMiddleware.user,
        children: [{
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
            // beforeEnter: AuthMiddleware.user,
            alias: '',
            meta: {
                title: 'Dashboard',
                metaTags: [{
                    name: 'Dashboard',
                    content: 'eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.'
                },
                {
                    property: 'og:description',
                    content: 'eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.'
                }
                ]
            },
        },
        {
            path: "/expire",
            name: "ExpirePage",
            component: Expire,
        },
        {
            name: 'invoice',
            path: '/invoice',
            component: Invoice
        },
        //logDetails
        {
            name: 'logDetails',
            path: '/logDetails',
            component: logDetails
        },
        //custom dashboard
        {
            path: '/custom/dashboard-list',
            component: customDashboardList,
            name: 'CustomDashboardList'
        },
        {
            path: '/custom/CustomEntityMention',
            component: customEntityMention,
            name: 'CustomEntitySuggestion'
        },
        //Roles
        {
            path: "/roles",
            component: Roles,
            name: "Roles",
        },

        {
            path: "/request-documents/all/:card_type?",
            name: "RequestDocuments",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/request-documents/upload",
            name: "upload-request-documents_",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/request-documents/:request_document_id/edit",
            name: "request-documents-edit",
            component: RequestDocumentDraft,
            // beforeEnter: AuthMiddleware.user
            meta: {
                title: 'Request Documents Edit'
            }
        },
        //Global Variables
        {
            path: "/global-variables",
            component: GlobalVariablesList,
            name: "GlobalVariables",

        },
        {
            path: "/global-variables/add",
            component: GlobalVariableAdd,

        },
        {
            path: "/global-variables/view/:global_variable_id",
            component: GlobalVariableAdd,
            name: "UpdateGlobalVariable",

        },
        {
            path: "/menu-management",
            name: "menuManagement",
            component: MenuManagement,
        },
        {
            path: "/company-entity-groups",
            name: "EntityGroupsDashboard",
            component: EntityGroupsDashboard,

        },
        {
            path: "/company-entity-groups/:group_id/preview",
            name: "EntityGroupPreview",
            component: EntityGroupPreview,
            beforeEnter: AuthMiddleware.user,
            children: [{
                path: ":entity_id",
                component: EntityGroupPreviewEntityData,
                name: "EntityGroupPreviewEntityData",
                beforeEnter: AuthMiddleware.user
            },
            ]
        },
        {
            path: '/formBuilders',
            name: 'FormBuilderList',
            component: FormBuilderList,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: '/fbd/:formbuilder_code',
            name: 'FormBuilderUserData',
            component: FormBuilderUserData,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/fbd/:formbuilder_code/data-list",
            name: "FormbuilderDataList",
            component: FormbuilderDataList,
            beforeEnter: AuthMiddleware.user,
        },
        {
            path: '/forms/approval-forms',
            name: 'approval-forms',
            component: ApprovalFormsView,
            beforeEnter: AuthMiddleware.user
        },
        {
            path: "/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderView",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/fb/view/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderViewStep",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            },
            ]
        }, {
            path: "/fb/:formbuilder_code/:formbuilder_data_id",
            name: "FormbuilderEdit",
            component: FormbuilderView,
            beforeEnter: AuthMiddleware.userOrApplicationUser,
            children: [{
                path: '/fb/edit/:formbuilder_code/:template_code/:formbuilder_data_id',
                name: "FormbuilderEditStep",
                component: FormbuilderViewStep,
                beforeEnter: AuthMiddleware.userOrApplicationUser,
            }
            ]
        },
        {
            path: "/templates",
            name: "Templates",
            component: AllTemplates,
            // beforeEnter: AuthMiddleware.user,
            meta: {
                title: 'Templates'
            }
        },
        {
            path: "/template/:template_id/menu/view",
            name: "ViewMenuTemplate",
            component: ViewTemplate,
        },
        {
            path: "/workflows",
            name: "Workflows",
            component: WorkflowList,
            meta: {
                title: 'Workflows'
            }
        },
        {
            path: "/workflow/:workflow_id",
            name: "Workflows-Data",
            component: WorkflowDataList,
            meta: {
                title: 'Workflow Data'
            }
        },
        {
            path: "/documents/all/:contact_id?",
            name: "Documents",
            component: Documents,
            meta: {
                title: 'Documents'
            }
        },
        {
            path: "/request-documents/all/:card_type?",
            name: "RequestDocuments_",
            component: RequestDocuments,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/request-documents/select-card-type/:contact_id?",
            name: "RequestDocumentsSelectCardType_",
            component: RequestDocumentsSelectCardType,
            meta: {
                title: 'Request Documents'
            }
        },
        {
            path: "/documents-signed",
            name: "SignDocuments",
            component: SignDocuments,
            meta: {
                title: 'Signed Documents'
            }
        },
        {
            path: "/documents/upload",
            name: "upload-documents",
            component: UploadDocumentPreference,
            meta: {
                title: 'Upload Documents'
            }
        },
        {
            path: "/request-documents/upload",
            name: "upload-request-documents",
            component: UploadRequestDocumentPreference,
            meta: {
                title: 'Upload Request Documents'
            }
        },
        {
            path: "/templates/upload",
            name: "upload-templates",
            component: UploadTemplatePreference,
            meta: {
                title: 'Upload Templates'
            }
        },
        // Profile Routes
        {
            path: '/profile',
            name: "profile",
            component: Profile,
            children: [{
                path: '/profile/personal-information',
                name: 'personal-information',
                component: PersonalInformation,
                meta: {
                    title: 'Personal Information'
                },
                alias: '/profile',
            },
            {
                path: '/profile/change-password',
                name: 'change-password',
                component: UpdatePassword,
                meta: {
                    title: 'Change Password'
                }
            },
            {
                path: '/profile/manage-permissions',
                name: 'manage-permissions',
                component: Permissions,
                meta: {
                    title: 'Manage Permissions'
                }
            },
            {
                path: '/profile/signature',
                name: 'signature',
                component: Signature,
                meta: {
                    title: 'Signature'
                }
            },
            {
                path: '/profile/initial',
                name: 'initial',
                component: Initial,
                meta: {
                    title: 'Initial'
                }
            },
            {
                path: '/profile/subscription',
                name: 'subscription',
                component: SubscriptionInformation,
                // component:PaymentGateway,
                meta: {
                    title: 'Subscription'
                }
            },
            {
                path: '/profile/companyUsers',
                name: 'companyUsers',
                component: CompanyUsers,
                meta: {
                    title: 'Company Users'
                }
            },
            {
                path: '/profile/companyProfile',
                name: 'companyProfile',
                component: Brandings,
                meta: {
                    title: 'Company Profile'
                }
            },

            ]
        },

        {
            path: "/plans-list",
            name: "Plans",
            component: plansList,
            meta: {
                title: 'E-signs plans List'
            }
        },
       
        {
            path: '/profile/paymentIntegration',
            name: 'pay-integrations',
            component: PaymentIntegrations,
            meta: {
                title: 'Subscription'
            }
            //PaymentIntegrations
        },


        {
            path: '/profile/paymentsCards',
            name: 'integrations',
            component: PaymentsCards,
            meta: {
                title: 'Subscription payment'
            }
        },
        {
            path: '/paymentStatus',
            name: 'PaymentStatus',
            component: paymentStatus,
            meta: {
                title: 'Payment Status Info'
            }
        },
        // {
        //     path: '/entity-payment-status-update',
        //     name: 'commonComponentForPayment',
        //     component: commonComponentForPayment,
        //     meta: {
        //         title: " Payment Update"
        //     }
        // },

        //profilesettings
        {
            path: '/profilesettings',
            name: "profilesettings",
            component: Profilesettings,
            children: [

                {
                    path: '/profilesettings/document-expiration-reminders',
                    name: 'expiration-reminders',
                    component: DocumentExpirationReminders,
                    meta: {
                        title: 'Document Expiration & Reminders'
                    }
                },
                {
                    path: '/profilesettings/application-settings',
                    name: 'application-settings',
                    component: ApplicationSettings,
                    meta: {
                        title: 'Settings'
                    }
                },
                {
                    path: '/profilesettings/document-notifications',
                    name: 'document-notifications',
                    component: Notifications,
                    meta: {
                        title: 'Notifications'
                    }
                },

                {
                    path: '/profilesettings/initial',
                    name: 'profilesettingsInitial',
                    component: Initial,
                    meta: {
                        title: 'Profile settings Initial'
                    }
                },
                {
                    path: '/profilesettings/dashboard-settings',
                    name: 'statistics',
                    component: Statistics,
                    meta: {
                        title: 'Dashboard Status'
                    },
                    alias: '/profilesettings',
                },
                {
                    path: '/profilesettings/myapps-credentials',
                    name: 'myapps-credentials',
                    component: AppCredentials,
                    meta: {
                        title: 'Myapps Credentials'
                    },

                },
                
                {
                    path: '/profilesettings/global-filters',
                    name: 'global-filters',
                    component: GlobalFilters,
                    meta: {
                        title: 'Global Filters'
                    },

                },
                {
                    path: '/profilesettings/app-mode',
                    name: 'app-mode-configuration',
                    component: AppModeConfiguration,
                    meta: {
                        title: 'App Mode'
                    },
                },
            ]
        },

        {
            path: '/branding',
            name: "Branding",
            component: Branding,
            meta: {
                title: 'Branding'
            }
        },

        {
            path: "/apps",
            name: "application",
            component: ApplicationList,
            meta: {
                title: 'Applications List'
            }
        },
        {
            path: "/developer/portal",
            name: "developer_portal",
            component:DeveloperPortal,
            meta: {
                title: 'Developer Portal'
            }
        },
        {
            path: "/developer/app-detail/:appId/portal",
            name: "clentApp_portal",
            component:ClientAppDetail,
            meta: {
                title: 'ClientApp Portal'
            }
        },
        {
            path: '/apps/published',
            name: 'PublishedAppsList',
            component: PublishedAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/apps/approvals',
            name: 'ApprovalAppsList',
            component: ApprovalAppsList,
            meta: {
                title: 'Your published apps'
            }
        },
        {
            path: '/apps/installed',
            name: 'InstalledAppsList',
            component: InstalledAppList,
            meta: {
                title: 'Your installed apps'
            }
        },
        {
            path: '/apps/upload',
            name: 'UploadYourOwnApp',
            component: UploadAppsSteps,
            meta: {
                title: 'Upload your own app to marketplace'
            }
        },
        {
            path: 'apps/create-from-excel',
            name: "CreateYourAppFromExcel",
            component: CreateApplicationFromExcel,
            meta: {
                title: 'Create application from excel'
            }

        },
        {
            path: '/apps/install/info',
            name: 'InstallApplication',
            component: InstallApp,
            meta: {
                title: 'Install a application from Marketplace to your workspace'
            }
        },
        {
            path: '/apps/new/steps',
            name: 'CreateYourApplication',
            component: ListAllSteps,
            meta: {
                title: 'Create Your Application from scratch'
            }
        },





        // Conatct Routes
        {
            path: "/contacts",
            name: "contacts",
            component: Contacts,
            meta: {
                title: 'Contacts'
            }
        },
        {
            path: "/contacts/documents/:contact_id",
            name: "user-contacts",
            component: UserDocuments,
            meta: {
                title: 'User Documents'
            }
        },
        {
            path: "/contacts/add",
            name: "add-contact",
            component: AddContacts,
            meta: {
                title: 'Add Contact'
            }
        },
        {
            path: "/contacts/edit/:contact_id",
            name: "edit-contact",
            component: AddContacts,
            meta: {
                title: 'Edit Contact'
            }
        },
        // Contact type 
        {
            path: "/contact-types",
            name: "contactTypes",
            component: ContactTypes,
            meta: {
                title: 'Contact Types'
            }
        },
        {
            path: "/signature/documents/:status",
            name: "signature-documents",
            component: Documents,
            meta: {
                title: 'Signature Documents'
            }
        },
        {
            path: "/send-document/mail/:id",
            name: "send-document-mail",
            component: sendDocumentOnMail,
        },
        {
            path: "/send-template/mail/:id/:document_id",
            name: "send-template-mail",
            component: sendTemplateOnMail,
        },
        {
            path: "/company/users",
            name: "Company Users",
            component: CompanyUserList,
            alias: '',
            meta: {
                title: 'Company Users'
            }
        },
        // template Routes



        {
            path: "/template",
            name: "Form Template",
            component: TemplateList,
            meta: {
                title: 'Create New Template '
            },
            alias: '',
        },
        {
            path: "/email-template/create",
            name: "CreateEmailTemplate",
            component: NewEmailTemplate,
            meta: {
                title: 'Create New Email Template '
            },
            alias: '',
        },
        {
            path: "/email-template/edit/:template_id",
            name: "EditEmailTemplate",
            component: NewEmailTemplate,
            meta: {
                title: 'Edit Email Template '
            },
            alias: '',
        },
        {
            path: "/email-header-footer-template/create",
            name: "CreateEmailHeaderFooterTemplate",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Create New Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/email-header-footer-template/edit/:template_id",
            name: "EditEmailHeaderFooterTemplate",
            component: NewEmailHeaderFooter,
            meta: {
                title: 'Edit Email Header Footer '
            },
            alias: '',
        },
        {
            path: "/email-template",
            name: "Email Templates List",
            component: EmailTemplatesList,
            meta: {
                title: 'Email Templates List'
            },
            alias: '',
        },
        {
            path : '/ap-user/custom-dashboards/:entityId',
            name : 'application-user-dashboards',
            component : ApplicationUserDashboards
        },
        {
            path : '/page-dashboard/:dashboardId',
            name : 'page-dashbaord',
            component : PageCustomDashboard
        },

        //Entities Route
        {
            path: "/entity-views",
            name: "EntityViewsList",
            component: EntitiesViewsList,
            meta: {
                title: 'Entity views list'
            }
        },
        {
            path: "/entity-views/add",
            name: "EntityViewAdd",
            component: EntitiesViewEdit,
            meta: {
                title: 'Entity view add'
            }
        },
        {
            path: "/entity-views/edit/:entity_view_id",
            name: "EntityViewEdit",
            component: EntitiesViewEdit,
            meta: {
                title: 'Entity view edit'
            }
        },
        {
            path: "/entity-views/view/:entity_view_id",
            name: "EntityViews",
            component: EntityViews,
            meta: {
                title: 'Entity views'
            }
        },
        {
            path: "/entity",
            name: "Entity List",
            component: EntitiesList,
            meta: {
                title: 'Entity List'
            }
        },
        {
            path: "/entity/:entity_id/excel/add",
            name: "entity-from-excel",
            component: createEntityFromExcel,
            meta: {
                title: 'Create Entity'
            }
        },
        {
            path: "/entity/:entity_id/add",
            name: "Create Entity",
            component: AddEditEntity,
            meta: {
                title: 'Create Entity'
            }
        },
        {
            path: "/entity/:entity_id/edit",
            name: "Edit Entity",
            component: AddEditEntity,
            meta: {
                title: 'Edit Entity'
            }
        },
        {
            path: "/entity/:entity_id/view",
            name: "View Entity",
            component: ViewEntity,
            meta: {
                title: 'View Entity'
            }
        },
        {
            path: "/entity/:entity_id",
            name: "EntityData",
            component: ViewEntityData,
            meta: {
                title: 'View Entity Data'
            }
        },
        {
            path: "/entity/view/:entity_id",
            name: "EntityDetailedView",
            component: EntityDetailedView,
            meta: {
                title: "Entity data view"
            }
        },
        {
            path: "/entity/edit/:entity_id",
            name: "EntityDetailedEdit",
            component: EntityDetailedView,
            meta: {
                title: "Entity data edit"
            }
        },
        {
            path: "/entity/:user_id/:template_code",
            name: "TemplatesData",
            component: UserTemplatesData,
            meta: {
                title: 'TemplatesData'
            }
        },
        {
            path: "/entity-execute/:entity_id",
            name: "EntityExecute",
            component: EntityExecute,
            children: [{
                path: '/entity-execute/:entity_id/:template_code',
                name: "EntityExecuteStep",
                component: EntityExecuteStep,
            }]
        },
        {
            path: "/entity_data_view/:entity_id",
            name: "PreviewEntityData",
            component: PreviewEntityData,
            children: [{
                path: '/entity_data_view/:entity_id/:template_code',
                name: 'PreviewEntityDataStep',
                component: PreviewEntityDataStep
            }]
        },
        {
            path: "/entityData-Edit/:entity_id",
            name: "EntityDataEdit",
            component: EntityExecute,
            children: [{
                path: '/entityData-Edit/:entity_id/:template_code',
                name: "EntityDataEditStep",
                component: EntityExecuteStep,
            }]
        },
        {
            path:'/iframeLink',
            name:'IframeLink',
            component:IframeLink,
        }

            //Entities Route End
        ]
    },

    //CUstome configuration Routes

    {
        path: '/configuration',
        component: configurationLayout,
        children:[{
            path: '',
            component: PaymentIntegrationsList,
        },
        {
            path:'/configuration/paymentSystems',
            name:"PaymentSystemConfigurations",
            component:PaymentIntegrationsList

        },
        {
            path: '/configuration/paymentSystems/add',
            component: PaymentIntegrations,
            name: 'AddPaymentIntegration'
        },

        {
            path: '/configuration/share_point',
            name: 'sharepoaint-app-integrations',
            component: SharePointIntegrationCredentials,
            meta: {
                title: 'Sharepoint  app integrations'
            },

        },
        {
            path: '/configuration/mail',
            name: 'MailIntegrations',
            component: MailConfiguration,
            meta: {
                title: 'Mail System Configuration'
            },

        },
    ]
    },

    //Scanner Route
    {
        path: "/scanner",
        name: "URLSacnner",
        component: Scanner,
    },
    {
        path:"/payments/:paymentAccountId/:transactionAccountId/:transactionCode",
        name:"PaymentLink",
        component:PaymentLinkComponent
    },
    /* form bulder */

    {
        path: "/template/create",
        name: "CreateFormTemplate",
        component: NewTemplate,
        meta: {
            title: 'Create New Template '
        },
        alias: '',
    },
    {
        path: "/template/:template_id/edit",
        name: "Edit Templates",
        component: NewTemplate,
        alias: '',
        meta: {
            title: 'Edit Template '
        }
    },
    {
        path: "/template/:template_id/view",
        name: "ViewTemplate",
        component: ViewTemplate,

    },
    {
        path: "/formBuilders/add",
        name: "new-form-builder",
        component: NewFormBuilder,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/formBuilders/:form_builder_id",
        name: "EditFormBuilders",
        component: NewFormBuilder,
        beforeEnter: AuthMiddleware.user,

    },
    // sent document
    {
        path: "/emp-documents/custom/finish",
        name: "finish-later",
        component: FinishLaterSuccess,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    {
        path: "/emp-documents/custom/:employee_document_id",
        name: "employee-documents-custom-document",
        component: ViewEmployeeCustomDocument,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    //success page 
    {
        path: "/emp-documents/custom/:employee_document/success",
        name: "signer-success-message",
        component: signSuccessMessage
    },
    {
        path: "/emp-documents/preview/:employee_document_id/pp/vp/:user_id",
        name: "document-preview",
        component: DocumentPreview,
    },
    {
        path: "/emp-documents/preview/:employee_document_id/pp/vp/:user_id/expired",
        name: "document-preview-expired",
        component: DocumentPreviewExpired,
    },
    {
        path: "/company-documents/:document_id/configure/template/:configurable_document_id/preview",
        name: "published-template-preiview",
        component: DocumentPreview
    },
    {
        path: "/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/auth/verify",
        name: "FormbuilderGuestAuth",
        component: DocumentPreview,
    },
    {
        path: "/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/vp/:user_id",
        name: "FormbuilderGuestUserAuth",
        component: DocumentPreview,
    },
    {
        path: "/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/:formbuilder_data_id/view/:user_id",
        name: "FormbuilderGuestUserPreviewAuthView",
        component: DocumentPreview,
    },
    {
        path: "/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/:formbuilder_data_id/vp/:user_id",
        name: "FormbuilderGuestUserPreviewAuth",
        component: DocumentPreview,
    },
    {
        path: "/public/fb/:formbuilder_details_id/view",
        name: "GuestFormbuilderView",
        component: FormbuilderNewView,
        beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected,
    },
    {
        path: '/public/fbd/:formbuilder_code/:template_code/:formbuilder_details_id',
        name: "CompanyFormbuilderExecuteStep",
        component: FormbuilderNewView,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/public/fbd/:formbuilder_code/:formbuilder_details_id/:formbuilder_data_id/status/completed",
        name: "CompanyUserFormbuilderCompleted",
        component: GuestFormbuilderCompleted,
    },
    //Approval page
    {
        path: "/public/fb/approval/auth/:formbuilder_details_id",
        name: "FormbuilderApprovalAuth",
        component: FormbuilderApprovalAuth,
    },
    {
        path: "/public/fb/approval/:formbuilder_details_id",
        name: "GuestFormbuilderApprovalExecute",
        component: FormbuilderNewView,
        beforeEnter: AuthMiddleware.guestApprovalUserFormbuilderPasswordProtected,
    },
    {
        path: "/public/fb/:formbuilder_details_id",
        name: "GuestFormbuilderExecute",
        component: FormbuilderNewView,
        beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected,

        children: [{
            path: '/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id',
            name: "GuestFormbuilderExecuteStep",
            component: GuestFormbuilderExecuteStep,
            beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected
        },
        {
            path: '/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/:formbuilder_data_id',
            name: "GuestFormbuilderExecuteUpdateStep",
            component: GuestFormbuilderExecuteStep,
            beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected
        },
        {
            path: '/public/fb/:formbuilder_code/:template_code/:formbuilder_details_id/:formbuilder_data_id/view',
            name: "GuestFormbuilderExecuteViewStep",
            component: GuestFormbuilderExecuteStep,
            beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected
        }
        ]
    },
    {
        path: "/public/fbd/:formbuilder_details_id/:user_id",
        name: "GuestFormbuilderUserSubmittedDataList",
        component: GuestFormbuilderUserSubmittedDataList,
        beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected
    },
    {
        path: "/public/fb/:formbuilder_code/:formbuilder_details_id/:formbuilder_data_id/status/completed",
        name: "GuestFormbuilderCompleted",
        component: GuestFormbuilderCompleted,
        beforeEnter: AuthMiddleware.guestUserFormbuilderPasswordProtected
    },
    //for declined
    {
        path: "/emp-documents/custom/:employee_document_id/pp/decline",
        name: "employee-documents-custom-document-pp-decline",
        component: ViewEmployeeCustomDocumentStatus,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    //for signed user
    {
        path: "/emp-documents/preview/:employee_document_id/pp/finish/success",
        name: "Finish-Later-Success",
        components: FinishLaterSuccess,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    {
        path: "/emp-documents/custom/:employee_document_id/pp/signed",
        name: "employee-documents-custom-document-pp-signed",
        component: ViewEmployeeCustomDocumentStatus,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    // for contacts document
    {
        path: "/emp-documents/custom/:employee_document_id/pp",
        name: "employee-documents-custom-document-pp",
        component: ViewEmployeeCustomDocument,
        beforeEnter: AuthMiddleware.passwordProtectedOrAuthUser
    },
    // for draft doc
    {
        path: "/emp-documents/custom/:employee_document_id/draft",
        name: "employee-documents-custom-document-draft",
        component: ViewEmployeeCustomDocument,
        beforeEnter: AuthMiddleware.user
    },
    // for edit doc
    {
        path: "/emp-documents/custom/:employee_document_id/edit",
        name: "employee-documents-custom-document-edit",
        component: ConfigureEmployeeDocumentDraft,
        beforeEnter: AuthMiddleware.user
    },
    // for edit request document
    {
        path: "/request-documents/:request_document_id/edit",
        name: "request-documents-edit_",
        component: RequestDocumentDraft,
        // beforeEnter: AuthMiddleware.user
        meta: {
            title: 'Request Documents Edit'
        }
    },

    // Admin routes which does not have side bar 
    {
        path: "/company-documents/:document_id/configure/image",
        name: "configure-document-image",
        component: ConfigureCompanyDocument,
    },

    {
        path: "/workflow/send/:workflow_data_id",
        name: "workflow-send",
        component: AddEditWorkflow,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/workflow/edit/:workflow_id",
        name: "workflow-edit",
        component: AddEditWorkflow,
        beforeEnter: AuthMiddleware.user
    },

    // page to create new document 
    {
        path: "/company-documents/:document_id/configure/document",
        name: "configure-custom-document",
        component: ConfigureDocTypeDocument,
    },


    {
        path: "/company-documents/:document_id/configure/document/:configurable_document_id/update",
        name: "edit-configure-custom-document",
        component: ConfigureDocTypeDocument,
    },



    {
        path: "/company-documents/:document_id/configure/image/:configurable_document_id/update",
        name: "edit-configure-document-image",
        component: ConfigureCompanyDocument,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/company-documents/:document_id/configure/template/image/:configurable_document_id/update",
        name: "edit-configure-template-image",
        component: ConfigureCompanyTemplate,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/company-documents/:document_id/configure/image/:configurable_document_id/preview",
        name: "edit-configure-template-image-preview",
        component: ConfigureCompanyTemplatePreview,
        beforeEnter: AuthMiddleware.user
    },
    {
        path: "/company-documents/custom/:document_id/:configurable_document_id/execute",
        name: "execute-custom-document",
        component: CustomDocumentExecute,
    },

    {
        path: "/company-documents/custom/:document_id/:configurable_document_id/preview",
        name: "preview-custom-document",
        component: CustomDocumentPreview,
    },

    {
        path: "/company-documents/custom/document/:document_id/:configurable_document_id/execute",
        name: "execute-custom-doc-type-document",
        component: CustomDocTypeDocumentExecute,
    },
    {
        path: "/company-documents/custom/:document_id/:configurable_document_id/execute/:workflow_data_id",
        name: "execute-custom-document-user",
        component: CustomDocumentExecute,
    },
    {
        path: "/company-documents/custom/:document_id/:configurable_document_id/preview/:workflow_data_id",
        name: "preview-custom-document-user",
        component: CustomDocumentPreview,
    },

    {
        path: "/company-documents/custom/document/:document_id/:configurable_document_id/execute/:workflow_data_id",
        name: "execute-custom-doc-type-document-user",
        component: CustomDocTypeDocumentExecute,
    },
    {
        path: "/company-documents/custom/document/:document_id/:configurable_document_id/preview/:workflow_data_id",
        name: "preview-custom-doc-type-document-user",
        component: CustomDocTypeDocumentPreview,
    },

    {
        path: "/company-documents/static/:document_id/execute",
        name: "execute-static-document",
        component: StaticDocumentExecute,
    },
    {
        path: "/company-documents/static/:document_id/execute",
        name: "execute-static-document-user",
        component: StaticDocumentExecute,
    },
    {
        path: "/company-documents/static/:document_id/preview/:workflow_data_id",
        name: "preview-static-document",
        component: StaticDocumentPreview,
    },
    {
        path: "/company-documents/requested/:document_id/execute/:workflow_data_id",
        name: "execute-requested-document-user",
        component: RequestedDocumentExecute,
    },
    {
        path: "/emp/documents/custom/:employee_document_id/pp/verify-password",
        name: "employee-custom-document-password-verification",
        component: EmployerCustomDocumentPasswordVerification
    },
    {
        path: "/emp/documents/custom/document/:employee_document_id/pp/verify-password",
        name: "employee-doc-type-document-password-verification",
        component: EmployerCustomDocTypePasswordVerification
    },
    // Admin routes
    {
        path: "/signin",
        name: "SignIn",
        component: SignIn,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/sso-signin",
        name: "SSOSignIn",
        component: SSOSignIn
    },
    {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
        beforeEnter: AuthMiddleware.guest
    },

    {
        path: "/sso-signup",
        name: "SSOSignUp",
        component: SSOSignUp,
        beforeEnter: AuthMiddleware.guest
    },

    {
        path: "/inviteUser",
        name: "InviteUser",
        component: InviteUser,
        beforeEnter: AuthMiddleware.guest
    },
    {

        path: "/join-us",
        name: "JoinUs",
        component: JoinUs,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/create-profile",
        name: "UserRegistrationProfile",
        component: UserRegistrationProfile,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/verify",
        name: "UserVerify",
        component: UserVerify,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/sso-verify",
        name: "SSOUserVerify",
        component: SSOUserVerify,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/send-verification",
        name: "SendVerification",
        component: SendVerification,
        beforeEnter: AuthMiddleware.guest
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        beforeEnter: AuthMiddleware.authenticatedRoutes

    },
    {
        path: "/forgot-password-verification",
        name: "ForgotPasswordVerification",
        component: ForgotPasswordVerification,
        beforeEnter: AuthMiddleware.authenticatedRoutes
    },
    {
        path: '/documents',
        redirect: '/dashboard',
        beforeEnter: AuthMiddleware.authenticatedRoutes
    },
    {
        path: '*',
        component: PageNotFound
    },
    //custom dashboard
    {
        path: '/custom/dashboard/config/:dashboardId',
        name: 'custom-dashboard-config',
        component: customDashboardConfig,
        beforeEnter: AuthMiddleware.user
    },

    //entity payment status 

    {
        path: '/entity-payment-status-update',
        name: 'commonComponentForPayment',
        component: commonComponentForPayment,
        meta: {
            title: " Payment Update"
        }
    },
    //Subscription payment status
    {
        path: '/payments-status',
        name: 'Stripe Payment Status',
        component: StripePaymentStatus,
        meta: {
            title: 'stripe payment info'
        }
    },

    {
        path: '/view/:entityDataId/:entityId/:templateId',
        name: 'EntityDetailedCardViewForPublic',
        component: EntityDetailedCardViewForPublic,
        meta: {
            title: "Public access to View data of entity"
        }
        // EntityDetailedCardViewForPublic
    },
    //oauth
    {
        path: "/signin/oauth",
        name: "OauthComponent",
        component: OauthComponent,

    },
    ...applicationUserRoutes,
    ...iFrameRoutes,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
