import { render, staticRenderFns } from "./CustomDialog.vue?vue&type=template&id=4d1d8d8c&scoped=true"
import script from "./CustomDialog.vue?vue&type=script&lang=js"
export * from "./CustomDialog.vue?vue&type=script&lang=js"
import style0 from "./CustomDialog.vue?vue&type=style&index=0&id=4d1d8d8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d1d8d8c",
  null
  
)

export default component.exports