import axios from "@/config/axios";
import oauthAxios from "axios";

export const postAPICall = async (
  method,
  endPoint,
  params = {},
  thirdParty = false
) => {
  try {
    let options = {
      method: method,
      url: endPoint,
    };

    if (method === "GET") {
      options.params = params;
    } else {
      options.data = params;
    }

    if (thirdParty) {
      const response = await oauthAxios(options);
      return response.data;
    }
    const response = await axios(options);
    return response.data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};
