import * as PrivateAxios from "axios"
import axios from "@/config/axios";

// initial state
const initialState = () => {
  return {
    documentUploadStatus: false,
    fileUploadStatus: false,
    fileUploadData: null,
    companyLogoData : null,
    base64FileUpload: null
    // VideoUpload:null,
    // fileUploadVideo:null

  };
};

const state = initialState();

const getters = {
  getDocumentUploadStatus: state => state.documentUploadStatus,
  getFileUploadStatus: state => state.fileUploadStatus,
  getFileUploadData: state => state.fileUploadData,
  getCompanyLogoUpdateData : state => state.companyLogoData,
  getBase64FileUpload : state => state.base64FileUpload,
  // getFileUploadVideo:state=>state. fileUploadVideo 
};

const mutations = {
  setDocumentUploadStatus: (state, status) => {
    state.documentUploadStatus = status
  },
  setFileUploadStatus: (state, status) => {
    state.fileUploadStatus = status
  },
  setFileUploadData: (state, data) =>{
    state.fileUploadData = data
  },
  /* We need to cross check Video field ****Start***** */
  setVideoFileUploadData: (state, data) =>{
    state.VideoUpload = data
  },
  setVideoFile: (state, data) =>{
    state.VideoUpload = data
  },
  /* We need to cross check Video field ****Start***** */
  setCompanyLogoUpdateData : (state, data) =>{
    state.companyLogoData = data
  },
  setBase64FileUpload : (state, data) =>{
    state.base64FileUpload = data
  }, 
  resetData: state => {
    const initial = initialState();
    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
};

const actions = {
  reset: function (context) {
    context.commit("s3FileUpload/resetData", null, {
      root: true
    });
  },
  // upload to s3 bucket using presigned url
  uploadDocumentToGeneratedUrl: async (context, params) => {
    try {
      context.commit("s3FileUpload/setDocumentUploadStatus", false, {
        root: true
      })
      let pAxios = PrivateAxios.create({})
      await pAxios.put(params.upload_url, params.file);
      context.commit("s3FileUpload/setDocumentUploadStatus", true, {
        root: true
      })
    } catch (error) {
      context.commit("s3FileUpload/setDocumentUploadStatus", false, {
        root: true
      })
    }
  },

  uploadFileToS3: async (context, params) => {
    try {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadData", false, {
        root: true
      });
      const response = await axios.put(`/upload/file-upload-s3`, params);
      console.log("respose", response.data.data)
      context.commit("s3FileUpload/setFileUploadData", response.data.data, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadStatus", true, {
        root: true
      });
    } catch (error) {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadData", {}, {
        root: true
      });
      console.log(error);
    }
  },

uploadVideoToS3: async (context, params) => {
  
  try {
    context.commit("s3FileUpload/setFileUploadStatus", false, {
      root: true
    });
    context.commit("s3FileUpload/setFileUploadData", false, {
      root: true
    });
    const response = await axios.post(`/VideoUpload`, params);
    console.log("respose", response.data.data)
    context.commit("s3FileUpload/setFileUploadData", response.data.data, {
      root: true
    });
    context.commit("s3FileUpload/setFileUploadStatus", true, {
      root: true
    });
  } catch (error) {
    context.commit("s3FileUpload/setFileUploadStatus", false, {
      root: true
    });
    context.commit("s3FileUpload/setFileUploadData", {}, {
      root: true
    });
    console.log(error);
  }
},

  uploadCompanyLogoToS3: async (context, params) => {
    try {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });
      context.commit("s3FileUpload/setCompanyLogoUpdateData", false, {
        root: true
      });
      const response = await axios.put(`/upload/company-logo-upload-s3`, params);
      context.commit("s3FileUpload/setCompanyLogoUpdateData", response.data.data, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadStatus", true, {
        root: true
      });
    } catch (error) {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });

    }
  },
  uploadMultipleBase64Files: async (context, params) => {
    try {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadData", false, {
        root: true
      });
      const response = await axios.post(`/base64MultiFileUpload`, params);
      console.log("response",response);
      context.commit("s3FileUpload/setFileUploadData", response.data.data, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadStatus", true, {
        root: true
      });
    } catch (error) {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });

    }
  },
  getDataFromPresignedUrls: async (context, params) => {
    try {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadData", false, {
        root: true
      });
      const response = await axios.post(`/files-data`, params);
      context.commit("s3FileUpload/setFileUploadData", response.data.data, {
        root: true
      });
      context.commit("s3FileUpload/setFileUploadStatus", true, {
        root: true
      });
    } catch (error) {
      context.commit("s3FileUpload/setFileUploadStatus", false, {
        root: true
      });

    }
  },
  uploadBase64File: async (context, params) => {
    try {
      context.commit("s3FileUpload/setBase64FileUpload", false, {
        root: true
      });
      const response = await axios.post('/upload/base64SingleFileUpload', params);
      context.commit("s3FileUpload/setBase64FileUpload", response.data.data, {
        root: true
      });
    } catch (error) {
      context.commit("s3FileUpload/setBase64FileUpload", false, {
        root: true
      });

    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};