import AuthMiddleware from "../middleware/auth"

const FormbuilderNewView = () => import('@/components/formBuilders/formBuilderNewView.vue');
const GuestFormbuilderUserSubmittedDataList = () => import('@/components/formBuilders/FormbuilderUserSubmittedDataList');
const GuestFormbuilderCompleted = () => import('@/components/formBuilders/formbuilderCompletedMessage')
const FormbuilderDataList = () => import('@/components/formBuilders/formbuilderTemplateDataList')
const ApplicationUserDashboard = () => import('@/components/applicationUsers/applicationUsersDashboard');
const ApplicationCustomDashboard = () => import('@/components/applicationUsers/CustomDashboard');
const PageCustomDashboard = () => import('@/components/applicationUsers/PageCustomDashboard');
const ApplicationUserDocuments = () => import('@/components/applicationUsers/applicationUsersDocuments');
const ApplicationUserWorkflows = () => import('@/components/applicationUsers/applicationUsersWorkflows');
const ApplicationUserApprovalForms = () => import('@/components/applicationUsers/applicationUsersApprovalForms');
const FormBuilderUserData = () => import('@/views/formBuilders/FormBuilderUserData');
const ApplicationUserFormbuilder = () => import('@/components/applicationUsers/applicationUsersFormbuilder');
const ApplicationUserProfile = () => import('@/components/applicationUsers/applicationUsersProfile');
const ViewEntityData = () => import('@/components/entity/ViewEntityData');
const EntityDetailedView = () => import('@/components/entity/EntityDetailedCardView.vue');
const EntityViews = () => import('@/components/entity/EntityViews');
const FormbuilderView = () => import('@/components/formBuilders/formbuilderView.vue');
const FormbuilderViewStep = () => import('@/components/formBuilders/formbuilderViewStep.vue');
const entityDashboardConfig = () => import('@/components/customDashboard/customDashboardConfig.vue');
const Expire = () => import("../components/account/Expire.vue");
const ContactLoginPage = () => import("@/views/contactLogin/loginPage.vue");
const ContactForbiddenPage = () => import("@/components/applicationUsers/applicationUsersForbidden.vue");

export default [
    {
        path: '/ap/fbd/:formbuilder_code/:template_code/:formbuilder_details_id',
        name: "ApplicationFormbuilderExecuteStep",
        component: FormbuilderNewView,
        beforeEnter: AuthMiddleware.applicationUser
    },
    {
        path: "/ap/fbd/:formbuilder_details_id/:user_id",
        name: "ApplicaionFormbuilderUserSubmittedDataList",
        component: GuestFormbuilderUserSubmittedDataList,
        beforeEnter: AuthMiddleware.applicationUser
    },
    {
        path: "/ap/fb/:formbuilder_code/:formbuilder_details_id/:formbuilder_data_id/status/completed",
        name: "ApplicaionFormbuilderCompleted",
        component: GuestFormbuilderCompleted,
        beforeEnter: AuthMiddleware.applicationUser
    },
    {
        path: "/ap/fbdv/:formbuilder_code/data-list",
        name: "ApplicaionFormbuilderDataList",
        component: FormbuilderDataList,
        beforeEnter: AuthMiddleware.applicationUser,
    },
    {
        path: '/ap',
        component: ApplicationUserDashboard,
        name: "application-user",
        beforeEnter: AuthMiddleware.applicationUser,
        children: [
            {
                path: '/ap/dashboard',
                name: 'application-user-dashboard',
                component: ApplicationCustomDashboard,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/page-dashboard/:dashboardId',
                name : 'application-user-page-dashboard',
                component : PageCustomDashboard,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/documents',
                name: 'application-user-documents',
                component: ApplicationUserDocuments,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/workflows',
                name: 'application-user-workflows',
                component: ApplicationUserWorkflows,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/approvalforms',
                name: 'application-user-approvalforms',
                component: ApplicationUserApprovalForms,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/fbd/:formbuilder_code',
                name: 'applicationFormBuilderUserData',
                component: FormBuilderUserData,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/formbuilder',
                name: 'application-user-formbuilder',
                component: ApplicationUserFormbuilder,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/profile',
                name: 'application-user-profile',
                component: ApplicationUserProfile,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: '/ap/entity/:entity_id/:menuId',
                name: 'application-user-entity',
                component: ViewEntityData,
                beforeEnter: AuthMiddleware.applicationUser
            },
            {
                path: "/ap/entity-execute/:entity_id",
                name: "ApEntityExecute",
                component: EntityDetailedView,
                beforeEnter: AuthMiddleware.applicationUser
                // children: [{
                //     path: '/ap/entityData-Edit/:entity_id/:template_code',
                //     name: "ApEntityExecuteStep",
                //     component: ApEntityExecuteStep,
                // }]
            },
            {
                path: "/ap/entity-view/:entity_id",
                name: "ApPreviewEntityData",
                component: EntityDetailedView,
                // children: [{
                //     path: '/ap/entity_data_view/:entity_id/:template_code',
                //     name: 'ApPreviewEntityDataStep',
                //     component: ApPreviewEntityDataStep
                // }]
            },
            {
                path : `/ap/entity-views/view/:entity_view_id`,
                name: "ApplicationUserEntityViews",
                component : EntityViews
            },
            //application user access
            {
                path: "/ap/fb/:formbuilder_code/:formbuilder_data_id",
                name: "ApplicationUserFormbuilderView",
                component: FormbuilderView,
                // beforeEnter: AuthMiddleware.applicationUser,
                children: [{
                    path: '/ap/fb/view/:formbuilder_code/:template_code/:formbuilder_data_id',
                    name: "ApplicationUserFormbuilderViewStep",
                    component: FormbuilderViewStep,
                    // beforeEnter: AuthMiddleware.applicationUser,
                },
                ]
            }, {
                path: "/ap/fb/:formbuilder_code/:formbuilder_data_id",
                name: "ApplicationUserFormbuilderEdit",
                component: FormbuilderView,
                beforeEnter: AuthMiddleware.applicationUser,
                children: [{
                    path: '/ap/fb/edit/:formbuilder_code/:template_code/:formbuilder_data_id',
                    name: "ApplicationUserFormbuilderEditStep",
                    component: FormbuilderViewStep,
                    beforeEnter: AuthMiddleware.applicationUser,
                }
                ]
            }
        ]
    },
    {
        path: '/ap/custom/dashboard/:entityId',
        name: 'entity-custom-dashboard-config',
        component: entityDashboardConfig,
        beforeEnter: AuthMiddleware.user
    },

    //app expire subscription
    {
        path: "/ap/expire",
        name: "AppUserExpirePage",
        component: Expire,
    },

    //Contact login
    {
        path: '/ap/signin',
        name: 'contact-login-page',
        component: ContactLoginPage,
        beforeEnter: AuthMiddleware.applicationGuest
    },
    {
        path: '/ap/forbidden',
        name: 'ap-forbidden',
        component: ContactForbiddenPage,
    },
]