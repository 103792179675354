import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchAndAddIconsToLocal = async () => {
  let icons = await fetchAllIcondFromDatabase();
  return await addIconsToLocal(icons);
};

export const addIconsToLocal = async (icons) => {
  return await db.icons.bulkAdd(
    icons.map((d) => {
      d.createdAt = new Date().toISOString();
      return d;
    })
  );
};

//fetch all icons
export const fetchAllIcons = async () => {
  let icons;
  icons = await fetchAllIconsFromLocal();
  if (!icons.length) {
    icons = await fetchAllIcondFromDatabase();
    await addIconsToLocal(icons);
  }
  return icons;
};

export const fetchAllIconsFromLocal = () => {
  return db.icons.toArray();
};

export const fetchAllIcondFromDatabase = async () => {
  const result = await postAPICall("GET", `/icons/getAllIcons`);
  return result.data;
};

//fetch single icon
export const fetchIconByName = async (name) => {
  let icon;
  icon = await fetchIconFromLocal(name);
  if (!icon) {
    icon = await fetchIconFromDatabase(name);
  }
  return icon;
};

export const fetchIconFromLocal = async (name) => {
  let result = await db.icons.get({ name: name });
  return result;
};
export const fetchIconFromDatabase = async (name) => {
  const result = await postAPICall("GET", `/icons/byName/${name}`);
  return result.data;
};
